import { render, staticRenderFns } from "./wechatcode.vue?vue&type=template&id=5fc8c453&scoped=true"
import script from "./wechatcode.vue?vue&type=script&lang=js"
export * from "./wechatcode.vue?vue&type=script&lang=js"
import style0 from "./wechatcode.vue?vue&type=style&index=0&id=5fc8c453&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc8c453",
  null
  
)

export default component.exports