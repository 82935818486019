<template>
	<div class="content">
		<indexTop title="试题搜索" :show="false"></indexTop>
		<div v-if="type == 0" class="search flex-center flex-midden">
			<div @click="type = 1,text='',image=''" class="search-item">
				<img class="search-item-bg" src="@/assets/images/search/bg1.png"/>
				<div class="search-item-content">
					<div class="search-item-title">拍照搜题</div>
					<div class="search-item-desc">相机拍照搜索</div>
					<img class="search-item-icon" src="@/assets/images/search/righ1.png"/>
				</div>
			</div>
			<div @click="type = 2,text='',image=''" class="search-item">
				<img class="search-item-bg" src="@/assets/images/search/bg2.png"/>
				<div class="search-item-content">
					<div class="search-item-title">文字搜题</div>
					<div class="search-item-desc">输入文字搜题</div>
					<img class="search-item-icon" src="@/assets/images/search/righ2.png"/>
				</div>
			</div>
		</div>
		
		<div v-if="type == 1" class="photo-search">
			<el-upload v-if="!image" :action="upload" name="file" :limit="1" :show-file-list="false" :before-upload="checkImage" :on-success="imageSuccess" style="text-align: center;">
				<div class="photo-search-img">
					<div class="photo-search-img-icon">
						<img class="search-item-bg" src="@/assets/images/search/photo.png"/>
						<div>请选择您的图片</div>
					</div>
				</div>
			</el-upload>
			<div class="cropper" v-else>
				<vue-cropper ref="cropper" :img="image" :info="true"
					:autoCrop="options.autoCrop" :autoCropWidth="options.autoCropWidth"
					:autoCropHeight="options.autoCropHeight" :fixedBox="options.fixedBox" :full="true"
					outputType="png" :outputSize="1"></vue-cropper>
			</div>
			
			<div class="photo-search-title">请注意旋转并竖屏显示题目图片（注红框内可裁剪）</div>
			
			<div class="photo-search-button flex-center flex-midden">
				<div @click="type = 0">取消</div>
				<div @click="submitImage">确定</div>
			</div>
		</div>
		
		
		<div v-if="type == 2" class="text-search">
			<textarea class="text-search-text" v-model="text" placeholder="请输入您搜到题目"></textarea>
			
			<div class="photo-search-button flex-center flex-midden">
				<div @click="type = 0">取消</div>
				<div @click="submitText">确定</div>
			</div>
		</div>
		
		<div v-if="type == 3" class="search-result">
			<div class="search-result-title">搜索结果</div>
			<div class="search-result-img" v-if="text">
				<div>{{text}}</div>
			</div>
			<div class="search-result-img" v-if="image">
				<img :src="image"></img>
			</div>
			<div class="search-result-desc">以下为相似度较高题目，请选择匹配题目</div>
			
			<div class="search-result-tab flex-start flex-center flex-wrap">
				<div v-for="item in examOffice" @click="selectSearch('examOffice',item.id)" :key="item.id" :class="item.id==search.examOffice?'active':''">{{item.name}}</div>
			</div>
			<div class="search-result-tab flex-start flex-center flex-wrap">
				<div v-for="item in subject" @click="selectSearch('subject',item.id)" :key="item.id" :class="item.id==search.subject?'active':''">{{item.name}}</div>
			</div>
			
			<div class="search-result-list flex-start flex-center flex-wrap">
				<img v-for="(item, index) in list" @click="showmsg(index)" v-if="(item.examOffice == search.examOffice || search.examOffice=='') && (item.subject == search.subject || search.subject=='')" :src="item.imageUrl"/>
			</div>
		</div>
		
		
		<div v-if="type == 4" class="search-info">
			<div class="search-info-desc">
				<div class="flex-start flex-center">
					<div><span>考试局</span>{{info.examOfficeName}}</div>
					<div><span>科目</span>{{info.subjectName}}</div>
					<div><span>年份</span>{{info.yearName}}</div>
					<div><span>考试季</span>{{info.examSeasonName}}</div>
					<div><span>难度</span>{{info.difficultyName}}</div>
				</div>
				<div class="flex-start flex-center">
					<div><span>知识点一</span>{{info.knowledgeName1}}</div>
					<div><span>知识点二</span>{{info.knowledgeName2}}</div>
					<div><span>知识点三</span>{{info.knowledgeName3}}</div>
				</div>
			</div>
			
			<div class="search-info-title">题目</div>
			
			<div class="search-info-content">
				<img :src="info.imageUrl" style="width: 100%;height: auto;"/>
			</div>
			<div class="flex-start">
				<div class="search-info-title">题目答案</div>
				<img :src="info.answerUrl" class="search-info-img" style="object-fit: contain;width: 70%;"/>
			</div>
			
			<div class="flex-start" v-if="info.videoUrl">
				<div class="search-info-title">视频讲解</div>
				<video :src="info.videoUrl" class="search-info-img" controls/>
				<!-- <img :src="info.videoUrlCover" class="search-info-img"/> -->
			</div>
		</div>
		
		<div v-if="type == 4" class="search-info-button flex-round flex-center">
			<div @click="showpdf('questionUrl')">查看原题文档</div>
			<div @click="showpdf('answerUrl')">查看原题答案</div>
			<div @click="showError">纠错</div>
		</div>
		<score ref="scoreRef" @ok="scoreSubmit"></score>
		<error ref="errorRef"></error>
	</div>
</template>

<script>
	import indexTop from "@/components/index-top.vue"
	import msg from "@/components/msg.vue"
	import {subjectsList,examoffices,uploadUrl} from "@/api/config"
	import {picsearchText,getUserByToken,questionInfo,pointsRuleKey,picsearchImage} from "@/api/index"
	import score from "@/components/score.vue"
	import error from "@/components/error.vue"
	import {
		VueCropper
	} from 'vue-cropper'
	
	export default {
		components:{
			indexTop,
			msg,
			score,
			error,
			VueCropper
		},
		data(){
			return {
				type:0,//进入到的步骤
				text:"",//文字搜索的内容
				examOffice: [],
				subject: [],
				search:{
					examOffice:"",
					subject:""
				},
				searchscore:0,//查看题目需要的分数
				info:{},
				pager:{},
				list:[],
				options: {
					autoCrop: true, // 是否默认生成截图框
					autoCropWidth: 492, // 默认生成截图框宽度
					autoCropHeight: 207, // 默认生成截图框高度
					fixedBox: false, // 固定截图框大小 不允许改变
				},
				image:"",
				upload:"",
				clickCount:0,
				searchId:0
			}
		},
		mounted() {
			this.upload = uploadUrl();
			pointsRuleKey("search_topic").then(res=>{
				this.searchscore = res.data.points < 0 ?res.data.points*-1:res.data.points;
			})
		},
		methods:{
			showmsg(index){
				if(this.list[index].show){
					this.type = 4;
				}else{
					getUserByToken().then(res=>{
						if(res.code == 0){
							if(res.data.points >= this.searchscore || res.data.vipExpireTime > 0){
								if(res.data.vipExpireTime > 0){
									this.scoreSubmit({index:index,type:1});
								}else{
									this.$refs.scoreRef.init(1,"查看本次题目的所有搜索结果要"+this.searchscore+"积分，是否查看",{index:index,type:1});
								}
							}else{
								this.$refs.scoreRef.init(2,"当前积分不足请充值或购买会员",{});
							}
						}else{
							this.$message.error(res.msg);
						}
					})
				}
			},
			//积分花费确认
			scoreSubmit(e){
				if(e.type == 1){//确认查看答案
					questionInfo({
						questionId:this.list[e.index].id,
						searchId:this.searchId,
						clickCount:this.clickCount
					}).then(res=>{
						if(res.code == 0){
							this.info = res.question;
							this.pager = res.paper;
							this.type = 4;
						}else{
							this.$message.error(res.msg);
						}
					})
				}
			},
			getConfig() {
				subjectsList().then(res => {
					this.subject = res.list;
				})
				examoffices().then(res => {
					this.examOffice = res.list;
				})
			},
			selectSearch(name,value){
				if(this.search[name] == value){
					this.search[name] = "";
				}else{
					this.search[name] = value;
				}
			},
			submitText(){
				if(this.text == ""){
					return this.$message.error("请输入文字内容");
				}
				picsearchText({text:this.text}).then(res=>{
					if(res.code == 0){
						if(res.questions.length == 0){
							return this.$message.error("暂无满足的数据");
						}else{
							this.getConfig();
							this.list = res.questions;
							this.clickCount = res.clickCount;
							this.searchId = res.searchId;
							this.type = 3;
						}
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			//提交图片搜索
			submitImage(){
				if(this.image == ""){
					return this.$message.error("请上传图片");
				}
				const loading = this.$loading({
				  lock: true,
				  text: '正在搜索中',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				try {
					
					this.$refs.cropper.getCropData(data => {
						const arr = data.split(',');
						picsearchImage({base64:arr[1]}).then(res => {
							if (res.code == 0) {
								loading.close();
								if(res.questions.length == 0){
									return this.$message.error("暂无满足的数据");
								}else{
									this.getConfig();
									this.list = res.questions;
									this.clickCount = res.clickCount;
									this.searchId = res.searchId;
									this.image = res.file;
									this.type = 3;
								}
							} else {
								loading.close();
								this.$message.error(res.msg);
							}
						})
					})
				} catch (e) {
					loading.close();
					this.$message.error("切图失败");
				}
			},
			showError(){
				this.$refs.errorRef.init(this.info.id);
			},
			showpdf(field){
				window.open("https://pcgld.internationaltestpaper.com/paper/examPaper/pdfViewer?fileUrl="+this.pager[field]);
			},
			//限制图片的相关格式和大小
			checkImage(file){
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg');
				const isLt2M = file.size / 1024 / 1024 < 6;
				if (!isJPG) {
				  this.$message.error('上传图片只能是JPG,JPEG,PNG格式!');
				}
				return isJPG && isLt2M;
			},
			//上传观测点的图片成功
			imageSuccess(info,e){
				if(e.response.code == 0){
					this.image = e.response.filePath;
				}else{
					this.$message.error("图片上传失败");
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../assets/table.scss";
	
	.cropper {
		width: 492px;
		height: 407px;
		margin: 0 auto;
	}
</style>