<template>
	<div class="usercneter">
		<infoTop></infoTop>
		<div class="center-content flex-start">
			<centerLeft :menu="4"></centerLeft>
			<img class="memeber-info-bg" src="@/assets/images/me/memeber-bg.png"/>
			<div class="memeber-info">
				<div class="memeber-info-title" v-if="info.vipExpireTime > 0">会员到期时间:{{vipTime}}</div>
				<div class="memeber-info-title" v-else>充值会员，畅享平台资源，敬请期待</div>
				<div class="memeber-info-list flex-start flex-center flex-wrap" v-if="list.length > 0">
					<div class="memeber-info-item" :class="index==selectIndex?'active':''" v-for="(item,index) in list" @click="selectIndex = index">
						<div class="memeber-info-item-day" v-if="item.type == 0">{{item.points}}积分</div>
						<div class="memeber-info-item-day" v-else>{{item.vipTime}}天</div>
						<div class="memeber-info-item-num"><span>¥</span>{{item.price/100}}</div>
						<div class="memeber-info-item-copy">¥{{item.markerprice/100}}</div>
					</div>
				</div>
				
				<div class="memeber-info-pay flex-start flex-center" v-if="list.length > 0">
					<div class="memeber-info-pay-zf flex-between flex-center" @click="zfbButton">
						<img src="@/assets/images/me/pay.png"/>
						<div>支付宝购买</div>
						<div>去购买</div>
					</div>
					<div class="memeber-info-pay-zf flex-between flex-center" style="background-color: rgba(240, 252, 239, 1);" @click="weixin">
						<img src="@/assets/images/me/wechat.png"/>
						<div>微信购买</div>
						<div style="background-color: rgba(67, 201, 62, 1);">去购买</div>
					</div>
				</div>
			</div>
		</div>
		
		<el-dialog title="微信支付" :visible.sync="showma" width="300px" :center="true" :append-to-body="true" :show-close="true" :close-on-click-modal="false" @close="closepay">
		 <div class="aggree">
			 <canvas ref="qrcodeCanvas" style="margin: 0 auto;"></canvas>
			 <div class="aggree-title">微信扫码进行支付</div>
		 </div>
		</el-dialog>
	</div>
</template>

<script>
	import {payList,pay,getUserByToken,pcNotify,zfbpay} from "@/api/index"
	import infoTop from "@/components/info-top.vue"
	import centerLeft from "@/components/center-left.vue"
	import QRCode from 'qrcode'
	import moment from "moment"
	export default {
		components:{
			infoTop,
			centerLeft
		},
		data(){
			return {
				list:[],
				selectIndex:0,
				info:{},
				showma:false,
				timeFun:null,
				orderNo:"",
				vipTime:""
			}
		},
		mounted() {
			this.getUser();
			payList().then(res => {
				this.list = res.list;
				this.selectIndex = 0;
			})
		},
		methods:{
			getUser(){
				
				getUserByToken().then(res=>{
					this.info = res.data;
					this.vipTime = moment(res.data.vipExpireTime).format("YYYY-MM-DD HH:mm:ss");
				})
			},
			weixin(){
				pay(this.list[this.selectIndex].id,{
					id:this.info.id,
					nickname:this.info.nickname,
					purchasePlatform:1
				}).then(res=>{
					if(res.code == 0){
						this.showma = true;
						this.orderNo = res.data.outTradeNo;
						setTimeout(()=>{
							QRCode.toCanvas(this.$refs.qrcodeCanvas,res.data.url, error => {
								if (error) console.error(error)
							})
							this.check();
						},500);
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			check(){
				if(this.timeFun != null){
					clearTimeout(this.timeFun);
				}
				pcNotify({outTradeNo:this.orderNo}).then(res=>{
					if(res.code == 0){
						if(res.data == 0){
							this.timeFun = setTimeout(()=>{
								this.check();
							}, 2000)
						}else{
							this.$message.success("支付成功");
							this.showma = false;
							setTimeout(()=>{
								//刷新当前页面
								window.history.go(0);
							}, 1000)
						}
					}
				})
				
			},
			closepay(e){
				if(this.timeFun != null){
					clearTimeout(this.timeFun);
				}
				this.showma = false;
			},
			zfbButton(){
				zfbpay(this.list[this.selectIndex].id,{
					id:this.info.id,
					nickname:this.info.nickname,
					purchasePlatform:1
				}).then(res=>{
					if(res.code == 0){
						window.open(res.url);
						this.$confirm('是否已完成支付?', '提示', {
						  confirmButtonText: '已完成',
						  cancelButtonText: '未完成',
						  type: 'warning'
						}).then(() => {
							window.history.go(0);
						}).catch(() => {        
						});
					}else{
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "./index.scss";
	.aggree{
		text-align: center;
	}
	.aggree-title{
		font-size: 12px;
		color: #999;
	}
</style>