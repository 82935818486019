<template>
	<div class="content">
		<indexTop title="PastPaper题库" :show="true"></indexTop>
		<div class="content-table">
			<el-table :data="tableData" style="width: 100%" @selection-change="handleSelectionChange" @row-click="getRow">
				<el-table-column type="selection" :selectable="handleSelectable" label="全选"></el-table-column>
				<el-table-column prop="name" label="真题试卷名称" align="left">
					<template slot="header" slot-scope="scope">
						<el-breadcrumb separator-class="el-icon-arrow-right">
						  <el-breadcrumb-item v-for="(item,index) in path" :key="item.parentId">
							  <span @click="changeTab(index)">{{item.name}}</span>
						  </el-breadcrumb-item>
						</el-breadcrumb>
					</template>
					<template slot-scope="scope">
						<div class="flex-start flex-center">
							<img v-if="scope.row.isFolder == 1" src="@/assets/images/fold.png" style="width: 20px;margin-right: 10px;"/>
							<img v-else src="@/assets/images/pdf.png" style="width: 20px;margin-right: 10px;"/>
							{{scope.row.name}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="date" label="" width="50">
					<template slot-scope="scope">
						<i class="el-icon-arrow-right"></i>
					</template>
				</el-table-column>
			</el-table>
			<div class="table-bottom flex-between flex-center">
				<div class="table-bottom-left flex-start flex-center">
					<div>已选择 <span>{{selectList.length}}</span> 条</div>
					<div class="flex-midden flex-center" @click="downZip">
						<img src="@/assets/images/down.png" />
						<span>下载真题试卷</span>
					</div>
				</div>


				<div class="table-bottom-right">
					<el-pagination :current-page="page" @size-change="changeSize" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="sizes, prev, pager, next, jumper" :total="total" @current-change="getNextPage">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import indexTop from "@/components/index-top.vue"
	import {
		subjectsList,
		examoffices,
		years,
		examseasons
	} from "@/api/config"
	import {studyFileList,batchDownload} from "@/api/index"
	export default {
		components: {
			indexTop
		},
		data() {
			return {
				tableData: [],
				page: 1,
				total: 0,
				pageSize: 10,
				path:[{name:'PastPaper题库',level:1,parentId:0}],
				pathIndex:0,
				selectList:[],
				type:1
				
			}
		},
		created() {
			this.getTopList();
		},
		methods: {
			getList() {
				studyFileList({
					limit: this.pageSize,
					offset: this.page,
					level: this.path[this.pathIndex].level,
					parentId: this.path[this.pathIndex].parentId,
					source:'client',
					fileType:this.type
				}).then(res => {
					if (res.code == 0) {
						this.tableData = res.data;
						this.total = res.total || 10;
					}
				})
			},
			handleSelectable(row, index) {
			  // 如果状态不等于 '0'，则不允许选择
			  return row.isFolder == 0;
			},
			changeTab(e){
				if(e != this.pathIndex){
					let path = [];
					this.path.map((item, index)=>{
						if(index <= e){
							path.push(item);
						}
					})
					this.path = path;
					this.pathIndex = e;
					this.getTopList();
				}
			},
			getTopList() {
				this.page = 1;
				this.getList();
			},
			getNextPage(e) {
				this.page = e;
				this.getList();
			},
			getRow(e){
				if(e.isFolder == 0){
					window.open("https://pcgld.internationaltestpaper.com/paper/examPaper/pdfViewer?fileUrl="+e.url);
				}else{
					this.path.push({name:e.name,level:this.pathIndex+2,parentId:e.id});
					this.pathIndex++;
					this.getTopList();
				}
			},
			//改变条数
			changeSize(e) {
				this.pageSize = e;
				this.getTopList();
			},
			handleSelectionChange(e){
				this.selectList = e;
			},
			downZip(){
				if(this.selectList.length == 0){
					return this.$message.error("请选择记录");
				}
				let urls = [];
				this.selectList.map(item=>{
					urls.push(item.url);
				})
				batchDownload({urls:urls.join(",")}).then(res=>{
					const url = window.URL.createObjectURL(new Blob([res]))
					const link = document.createElement("a")
					link.href = url
					link.setAttribute("download",'试卷.zip')//导出的格式一定要和接口返回的文件一致
					document.body.appendChild(link)
					link.click()
					window.URL.revokeObjectURL(url)
					document.body.removeChild(link)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../assets/table.scss";
	::v-deep .el-table .cell{
		line-height: 26px;
		text-align: left;
		font-size: 14px;
		color: #16191E;
		cursor: pointer;
	}
</style>