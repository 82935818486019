<template>
  <div>
    
    <pdf
      :page="pageNum"
      :src="url"
      @progress="loadedRatio = $event"
      @num-pages="pageTotalNum=$event" 
      ></pdf>
    <div class="bottom">
      <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="prePage">上一页</el-button>
	  <div style="color: #409EFF">{{ pageNum }} / {{ pageTotalNum }}</div>
      <el-button type="primary" size="mini" @click="nextPage">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
    </div>
  </div>
</template>


<script>
import pdf from 'vue-pdf'
export default {
  name: 'Pdf',
  components: {
    pdf,
  },
  data() {
    return {
      url: '',
      pageNum: 1,//文件页码
      pageTotalNum: 1, //总页数
      loadedRatio: 0, //当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
    }
  },
  created(){
  	this.url = this.$route.query.url;
　　// 有时候pdf文件地址会出现跨域的情况，这里最好处理一下
　　this.url = pdf.createLoadingTask(this.url)
  },
  methods: {
    // 上一页
    prePage() {
	window.scrollTo(0, 0);
      let page = this.pageNum
      page = page > 1 ? page - 1 : this.pageTotalNum
      this.pageNum = page
    },
    // 下一页
    nextPage() {
	window.scrollTo(0, 0);
      let page = this.pageNum
      page = page < this.pageTotalNum ? page + 1 : 1
      this.pageNum = page
    }
  }
}
</script>
<style lang="scss">
	.bottom{
		position: fixed;
		bottom: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
		left: 50%;
		div{
			margin: 0px 10px;
		}
	}
</style>
 