import { render, staticRenderFns } from "./hsitoryinfo.vue?vue&type=template&id=7e6d1d90&scoped=true"
import script from "./hsitoryinfo.vue?vue&type=script&lang=js"
export * from "./hsitoryinfo.vue?vue&type=script&lang=js"
import style0 from "./hsitoryinfo.vue?vue&type=style&index=0&id=7e6d1d90&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e6d1d90",
  null
  
)

export default component.exports