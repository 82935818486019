<template>
	<div class="login">
		<div class="login-login">
			<div class="login-login-bg">
				<img src="@/assets/images/index/bg-icon.png"/>
			</div>
			<div class="login-model">
				<div class="login-model-text">注册</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="ruleForm">
					<el-form-item prop="mobile">
						<el-input v-model="ruleForm.mobile" placeholder="请输入您的手机号码"></el-input>
					</el-form-item>
					<el-form-item prop="verify">
						<el-input v-model="ruleForm.verify" placeholder="请输入验证码">
							<div slot="suffix" class="send-code" v-if="send">{{time}}S</div>
							<div slot="suffix" class="send-code" v-else @click="sendCode">获取验证码</div>
						</el-input>
					</el-form-item>
					<el-form-item prop="email">
						<el-input v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
					</el-form-item>
					<!-- <el-form-item prop="emailVerify">
						<el-input v-model="ruleForm.emailVerify" placeholder="请输入验证码">
							<div slot="suffix" class="send-code" v-if="sendEmailStatus">{{timeEmail}}S</div>
							<div slot="suffix" class="send-code" v-else @click="sendEmail">获取验证码</div>
						</el-input>
					</el-form-item> -->
					<el-form-item prop="password">
						<el-input :type="showpwd?'text':'password'" v-model="ruleForm.password" placeholder="请输入密码">
							<img v-if="showpwd" @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/open.png"/>
							<img v-else @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/see.png"/>
						</el-input>
					</el-form-item>
					<el-form-item prop="passwordTwo">
						<el-input :type="showpwd?'text':'password'" v-model="ruleForm.passwordTwo" placeholder="请再次输入确认密码">
							<img v-if="showpwd" @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/open.png"/>
							<img v-else @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/see.png"/>
						</el-input>
					</el-form-item>
					<el-form-item>
						<div class="form-button">
							<div @click="tolast">取消</div>
							<div @click="submitForm('ruleForm')">下一步</div>
						</div>
					</el-form-item>
					<div class="agree" style="margin-top: 37px;" @click="isselect=!isselect">
						<img v-if="isselect" src="@/assets/images/select.png"/>
						<img v-else src="@/assets/images/noselect.png"/>
						<div>我已阅读并同意<a @click.stop="showagree(5)">《隐私政策》</a>和<a @click.stop="showagree(4)">《用户协议》</a></div>
					</div>
				</el-form>
			</div>
		</div>
		<agree ref="agree" @close="isselect=true"></agree>
	</div>
</template>

<script>
	import agree from "@/components/agree.vue"
	import {sendSms,sendEmail,pcRegister} from "@/api/index"
	export default {
		components:{
			agree
		},
		data(){
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
				  callback(new Error('请再次输入密码'));
				} else if (value !== this.ruleForm.password) {
				  callback(new Error('两次输入密码不一致!'));
				} else {
				  callback();
				}
			  };
			return {
				isselect:false,
				ruleForm:{
					mobile:"",
					verify:"",
					password:"",
					confirmPassWord:"",
					email:"",
					emailVerify:""
				},
				rules:{
					mobile: [
						{ required: true, message: '请输入手机号', trigger: 'blur' },
					],
					verify: [
						{ required: true, message: '请输入手机号验证码', trigger: 'blur' }
					],
					email: [
						{ required: true, message: '请输入邮箱', trigger: 'blur' },
					],
					// emailVerify: [
					// 	{ required: true, message: '请输入邮箱验证码', trigger: 'blur' }
					// ],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' }
					],
					passwordTwo: [
						{ validator: validatePass2, trigger: 'blur' }
					]
				},
				showpwd:false,//是否展示密码
				send:false,
				time:0,
				timeFun:null,
				sendEmailStatus:false,
				timeEmail:0,
				timeEmailFun:null
			}
		},
		methods:{
			showagree(type){
				this.$refs.agree.init(type);
			},
			
			sendEmail(){
				if(this.sendEmailStatus){return false;}
				if(this.ruleForm.email == ""){
					return this.$message.error("请输入邮箱");
				}
				let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
				if(!reg.test(this.ruleForm.email)){
					return this.$message.error("请输入正确的邮箱");
				}
				sendEmail({email:this.ruleForm.email}).then(res=>{
					if(res.code == 0){
						this.sendEmailStatus = true;
						this.timeEmail = 60;
						this.changeTime();
					}else{
						return this.$message.error(res.msg);
					}
				})
				
			},
			changeEmailTime(){
				if(this.timeEmailFun != null){
					clearTimeout(this.timeEmailFun);
				}
				this.timeEmailFun = setTimeout(()=>{
					this.timeEmail--;
					if(this.timeEmail > 0){
						this.changeEmailTime();
					}else{
						this.sendEmailStatus = false;
					}
				},1000);
			},
			sendCode(){
				if(this.send){return false;}
				if(this.ruleForm.mobile == ""){
					return this.$message.error("请输入手机号");
				}
				let reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
				if(!reg.test(this.ruleForm.mobile)){
					return this.$message.error("请输入正确的手机号");
				}			
				sendSms({phone:this.ruleForm.mobile}).then(res=>{
					if(res.code == 0){
						this.send = true;
						this.time = 60;
						this.changeTime();
					}else{
						return this.$message.error(res.msg);
					}
				})
				
			},
			changeTime(){
				if(this.timeFun != null){
					clearTimeout(this.timeFun);
				}
				this.timeFun = setTimeout(()=>{
					this.time--;
					if(this.time > 0){
						this.changeTime();
					}else{
						this.send = false;
					}
				},1000);
			},
			submitForm(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(this.isselect == false){
							return this.$message.error("请阅读并同意相关协议");
						}
						pcRegister({
							mobile:this.ruleForm.mobile,
							verify:this.ruleForm.verify,
							email:this.ruleForm.email,
							emailVerify:this.ruleForm.emailVerify,
							password:this.ruleForm.password,
							confirmPassWord:this.ruleForm.passwordTwo
						}).then(res=>{
							if(res.code == 0){
								this.$cookies.set('userToken', res.token);
								this.$cookies.set('userId', res.user.id);
								this.$router.replace("/next");
							}else{
								return this.$message.error(res.msg);
							}
						})
					} else {
						return false;
					}
				});
			},
			tolast(){
				this.$router.push("/login");
			},
			toRegister(){
				this.$router.push("/register");
			}
		}
	}
</script>

<style lang="scss" scoped>
	.login{
		width: 100%;
		height: 100vh;
		background-image: url(../assets/images/login/bg.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}
	.login-login{
		display: flex;
		justify-content: center;
		align-items: center;
		top: 50%;
		right: 15%;
		position: absolute;
		transform: translate(0%, -50%);
		.login-login-bg{
			position: relative;
			img{
				&:first-of-type{
					width: 55%;
					height: auto;
					margin-left: 10%;
				}
			}
		}
	}
	.login-model{
		width: 690px;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 54px 0px 47px;
		.login-model-text{
			font-weight: bold;
			font-size: 24px;
			color: #16191E;
			line-height: 33px;
			text-align: center;
		}
	}
	.ruleForm{
		margin: 38px auto 0px;
		width: 370px;
		.pwd-see{
			width: 28px;
			height: 28px;
			position: absolute;
			top: 50%;
			margin-top: -14px;
			right: 18px;
			cursor: pointer;
		}
		.send-code{
			height: 22px;
			font-weight: 400;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			border-left: 1px solid #E3E3E3;
			width: 120px;
			text-align: center;
			position: absolute;
			top: 50%;
			right: 0px;
			margin-top: -11px;
			cursor: pointer;
		}
	}
	::v-deep .el-input__inner{
		width: 370px;
		height: 50px;
		background: #F9F9F9;
		border-radius: 6px;
		border: 0px;
	}
	::v-deep .el-form-item{
		margin-bottom: 18px;
	}
	.login-button{
		font-weight: 400;
		font-size: 14px;
		color: #3C4658;
		line-height: 20px;
		margin-top: 13px;
		display: flex;
		justify-content: space-between;
		div{
			cursor: pointer;
			font-weight: 400;
			font-size: 14px;
			color: #3C4658;
			line-height: 20px;
			text-align: right;
		}
	}
	.form-button{
		margin-top: 27px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		div{
			&:first-of-type{
				width: 166px;
				height: 50px;
				border-radius: 6px;
				border: 1px solid #FFB80F;
				font-weight: 400;
				font-size: 16px;
				color: #FFB80F;
				line-height: 50px;
				text-align: center;
				cursor: pointer;
			}
			&:last-of-type{
				width: 166px;
				height: 50px;
				border-radius: 6px;
				background: #FFB80F;
				border: 1px solid #FFB80F;
				font-weight: 400;
				font-size: 16px;
				color: #fff;
				line-height: 50px;
				text-align: center;
				cursor: pointer;
			}
		}
	}
	.wechat{
		text-align: center;
		margin-top: 43px;
		cursor: pointer;
		img{
			width: 55px;
			height: 55px;
		}
		div{
			font-weight: 400;
			font-size: 12px;
			color: #3C4658;
			line-height: 17px;
			margin-top: 10px;
		}
	}
	.agree{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 23px;
		cursor: pointer;
		img{
			width: 14px;
			height: 14px;
			margin-right: 8px;
		}
		div{
			font-weight: 400;
			font-size: 12px;
			color: #3C4658;
			line-height: 17px;
			a{
				color: #FFB80F;
			}
		}
	}
</style>